import ReduxPersist from 'config/ReduxPersistConfig';
import { BrowserHistory } from 'history';
import * as R from 'ramda';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';

import OrganizationsReducer, {
  STATE_KEY as ORGANIZATIONS_STATE_KEY,
} from 'components/SelectOrganization/SelectOrganizationReducer';
import GlobalReducer, {
  STATE_KEY as GLOBAL_STATE_KEY,
} from 'modules/GlobalReducer';
import AuthReducer, {
  STATE_KEY as AUTH_STATE_KEY,
} from 'modules/auth/AuthReducer';
import ControlTypesReducer, {
  STATE_KEY as CONTROL_TYPES_STATE_KEY,
} from 'modules/controlTypes/ControlTypesReducer';
import ExperimentsReducer, {
  STATE_KEY as EXPERIMENTS_STATE_KEY,
} from 'modules/experiments/ExperimentsReducer';
import ExploreReducer, {
  STATE_KEY as EXPLORE_STATE_KEY,
} from 'modules/explore/ExploreReducer';
import FeedbackReducer, {
  STATE_KEY as FEEDBACK_STATE_KEY,
} from 'modules/feedback/FeedbackReducer';
import FilesReducer, {
  STATE_KEY as FILES_STATE_KEY,
} from 'modules/files/FilesReducer';
import InstrumentsReducer, {
  STATE_KEY as INSTRUMENTS_STATE_KEY,
} from 'modules/instruments/InstrumentsReducer';
import MaterialTypesReducer, {
  STATE_KEY as MATERIAL_TYPES_STATE_KEY,
} from 'modules/materialTypes/MaterialTypesReducer';
import MaterialsReducer, {
  STATE_KEY as MATERIALS_STATE_KEY,
} from 'modules/materials/MaterialsReducer';
import MeasurementTypesReducer, {
  STATE_KEY as MEASUREMENT_TYPES_STATE_KEY,
} from 'modules/measurementTypes/MeasurementTypesReducer';
import MeasurementsReducer, {
  STATE_KEY as MEASUREMENTS_STATE_KEY,
} from 'modules/measurements/MeasurementsReducer';
import ProcessTypesReducer, {
  STATE_KEY as PROCESS_TYPES_STATE_KEY,
} from 'modules/processTypes/ProcessTypesReducer';
import ProcessesReducer, {
  STATE_KEY as PROCESSES_STATE_KEY,
} from 'modules/processes/ProcessesReducer';
import RelatedReducer, {
  STATE_KEY as RELATED_STATE_KEY,
} from 'modules/related/RelatedReducer';
import RouterReducer, {
  STATE_KEY as ROUTER_STATE_KEY,
} from 'modules/router/router.reducer';
import SitesReducer, {
  STATE_KEY as SITES_STATE_KEY,
} from 'modules/sites/SitesReducer';
import TeamsReducer, {
  STATE_KEY as TEAMS_STATE_KEY,
} from 'modules/teams/TeamsReducer';
import UsersReducer, {
  STATE_KEY as USERS_STATE_KEY,
} from 'modules/users/UsersReducer';
import VisualizeReducer, {
  STATE_KEY as VISUALIZE_STATE_KEY,
} from 'modules/visualize/VisualizeReducer';

const persist = R.curry(persistReducer)(ReduxPersist.storeConfig);

export default (history: BrowserHistory) =>
  R.compose(
    persist,
    combineReducers,
    R.assoc(GLOBAL_STATE_KEY, GlobalReducer),
    R.assoc(ROUTER_STATE_KEY, RouterReducer(history)),
    R.assoc(MATERIALS_STATE_KEY, MaterialsReducer),
    R.assoc(EXPERIMENTS_STATE_KEY, ExperimentsReducer),
    R.assoc(PROCESSES_STATE_KEY, ProcessesReducer),
    R.assoc(MEASUREMENTS_STATE_KEY, MeasurementsReducer),
    R.assoc(MATERIAL_TYPES_STATE_KEY, MaterialTypesReducer),
    R.assoc(PROCESS_TYPES_STATE_KEY, ProcessTypesReducer),
    R.assoc(MEASUREMENT_TYPES_STATE_KEY, MeasurementTypesReducer),
    R.assoc(CONTROL_TYPES_STATE_KEY, ControlTypesReducer),
    R.assoc(USERS_STATE_KEY, UsersReducer),
    R.assoc(TEAMS_STATE_KEY, TeamsReducer),
    R.assoc(SITES_STATE_KEY, SitesReducer),
    R.assoc(INSTRUMENTS_STATE_KEY, InstrumentsReducer),
    R.assoc(FILES_STATE_KEY, FilesReducer),
    R.assoc(FEEDBACK_STATE_KEY, FeedbackReducer),
    R.assoc(EXPLORE_STATE_KEY, ExploreReducer),
    R.assoc(RELATED_STATE_KEY, RelatedReducer),
    R.assoc(AUTH_STATE_KEY, AuthReducer),
    R.assoc(VISUALIZE_STATE_KEY, VisualizeReducer),
    R.assoc(ORGANIZATIONS_STATE_KEY, OrganizationsReducer),
  )({});
