export const codeUserValidator = () => ({
  required: 'Required',
  maxLength: {
    value: 4,
    message: 'Code should be exactly 4 characters',
  },
  minLength: {
    value: 4,
    message: 'Code should be exactly 4 characters',
  },
  pattern: {
    value: /^[A-Z0-9]+$/,
    message: 'The code should consist of A-Z, 0-9 only',
  },
});

export const codeMaterialTypeValidator = () => ({
  required: 'Required',
  minLength: {
    value: 2,
    message: 'Code should be exactly 2 characters',
  },
  pattern: {
    value: /^[A-Za-z0-9]+$/,
    message: 'The code should consist of A-Z, 0-9 only',
  },
});

export const codeProcessTypeValidator = () => ({
  required: 'Required',
  minLength: {
    value: 3,
    message: 'Code should be exactly 3 characters',
  },
  pattern: {
    value: /^[A-Za-z0-9]+$/,
    message: 'The code should consist of A-Z, 0-9 only',
  },
});

export const numericDimenstionValidation = () => ({
  required: 'Required',
  minLength: {
    value: 3,
    message: 'Code should be more than 3 characters',
  },
  pattern: {
    value: /^[0-9]+$/,
    message: 'The code should consist of 0-9 only',
  },
});

export const nameValidator = () => ({
  required: true,
  pattern: {
    //eslint-disable-next-line
    value: /^[A-Za-z0-9]+([\sA-Za-z0-9-,:;~!@#^=()\[\]{}.+?|$%_])*[A-Za-z0-9]$/,
    message:
      'The name should begin and end with a-z,A-Z,0-9.  Characters allowed internally consist of a-z,A-Z,0-9,-,:;~!@#^=()[]{}.+?|$%_ and spaces',
  },
});

export const emailValidator = () => ({
  required: 'Required',
  pattern: {
    value:
      /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    message: 'Email not valid',
  },
});

export const passwordValidator = () => ({
  required: 'Required',
  minLength: {
    value: 8,
    message: 'Should be at least 8 characters.',
  },
  pattern: {
    value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!^%*?&])[A-Za-z\d@$!^%*?&]/,
    message: 'Should have at least one uppercase, one symbol, one number',
  },
});

export const signUpPasswordValidator = () => ({
  required: 'Required',
  minLength: {
    value: 12,
    message: 'Should be at least 12 characters.',
  },
  pattern: {
    value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!^%*?&])[A-Za-z\d@$!^%*?&]/,
    message:
      'Must be at least 12 characters and include one number, one symbol and one uppercase',
  },
});

export const voidReasonValidator = () => ({
  validate: (value: string) => {
    if (!Boolean(value.trim())) {
      return 'There must be at least one non-whitespace character';
    } else if (value.length > 127) {
      return 'There must be no more than 127 characters';
    }
    return true;
  },
});

export const nameLinkValidator = () => ({
  required: 'Required',
  maxLength: {
    value: 127,
    message: 'Link Title exceeds maximum length of 127 characters',
  },
});

export const urlLinkValidator = () => ({
  required: 'Required',
  minLength: {
    value: 2,
    message: 'URL should be exactly 2 characters',
  },
  pattern: {
    value: /^(http|https):\/\//,
    message: 'The URL must begin with http:// or https://',
  },
  setValueAs(value: string) {
    return value.replaceAll('&amp;', '&');
  },
});

export const chartTitleValidator = () => ({
  required: {
    value: true,
    message: 'The name is required',
  },
  maxLength: {
    value: 40,
    message: 'The name should be less than 40 characters',
  },
});

export const chartSizeValidator = () => ({
  width: {
    min: {
      value: 400,
      message: 'The width should be more than 400px',
    },
    max: {
      value: 2400,
      message: 'The width should be less than 2400px',
    },
    pattern: {
      value: /^[0-9]+$/,
      message: 'Should contain only numbers',
    },
    required: {
      value: true,
      message: 'The width is required',
    },
  },
  height: {
    min: {
      value: 300,
      message: 'The height should be more than 300px',
    },
    max: {
      value: 2400,
      message: 'The height should be less than 2400px',
    },
    minLength: {
      value: 2,
      message: 'The height should be more than 300px',
    },
    pattern: {
      value: /^[0-9]+$/,
      message: 'Should contain only numbers',
    },
    required: {
      value: true,
      message: 'The height is required',
    },
  },
});

export const chartNumericFieldValidator = () => ({
  required: {
    value: true,
    message: 'The field is required',
  },
  pattern: {
    value: /^[-+]?[0-9]*\.?[0-9]+$/,
    message: 'Should contain only numbers',
  },
});
