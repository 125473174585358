import { useAppSelector } from 'hooks/reduxHooks';
import { Configuration } from 'modules/GlobalReducer';
import { getConfiguration } from 'modules/GlobalSelectors';
import { getUserInfo } from 'modules/auth/AuthSelectors';
import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

interface ConfiguredRouteProps {
  children: ReactNode;
  path: string;
}

const ConfiguredRoute: React.FC<ConfiguredRouteProps> = ({
  children,
  path,
}) => {
  const {
    data: configuration,
    loading: isConfigurationLoading,
  }: Configuration = useAppSelector(getConfiguration);
  const user = useAppSelector(getUserInfo);
  const navigate = useNavigate();

  const isHidden =
    isConfigurationLoading ||
    configuration?.disabledPages?.some((disabledPath: string) =>
      path?.includes(disabledPath),
    );

  const teamQuery = user?.teams?.length
    ? `?team:in=${user.teams.join(',')}`
    : '';
  const newPath = `/experiment${teamQuery}`;

  if (isHidden) {
    navigate(newPath);
    return null;
  }

  return <>{children}</>;
};

export default ConfiguredRoute;
