import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Button, Menu, MenuItem, Typography } from '@mui/material';
import SelectOrganization from 'components/SelectOrganization/SelectOrganization';
import { Organization } from 'components/SelectOrganization/SelectOrganizationReducer';
import { getOrganizationsData } from 'components/SelectOrganization/SelectOrganizationSelector';
import * as actionTypes from 'modules/GlobalActionsTypes';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { onOrganizationChanged } from 'utils/utils';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { logout } from '../../modules/auth/AuthActions';
import {
  getAccessToken,
  getUserInfo,
  isUserInfoLoading,
} from '../../modules/auth/AuthSelectors';
import colors from '../../theme/patterns/colors';

export const TOP_NAVBAR_HEIGHT = 30;

const TopNavbar = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { loading, data, selectedOrganization } =
    useAppSelector(getOrganizationsData);

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const { email } = useAppSelector(getUserInfo);
  const isUserInfoFetching = useAppSelector(isUserInfoLoading);
  const access_token = useAppSelector(getAccessToken);
  const user = useAppSelector(getUserInfo);

  const handleClickAuthMenu = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseAuthMenu = () => {
    setAnchorEl(null);
  };

  const handleClickLogout = () => {
    dispatch(logout(access_token));
    setAnchorEl(null);
  };
  const handleClickChangePassword = () => {
    setAnchorEl(null);
    return navigate('/auth/change-password');
  };

  const onOrganizationSelect = (
    organization: Organization,
    showOrganizationUrl: boolean,
  ) => {
    const teamQuery = user?.teams?.length
      ? `?team:in=${user.teams.join(',')}`
      : '';
    if (showOrganizationUrl) {
      dispatch({
        type: actionTypes.UPDATE_USER_ORGANIZATION,
        payload: organization,
      });
      navigate(`/${organization.namespace}/experiment${teamQuery}`);
    } else {
      dispatch({
        type: actionTypes.UPDATE_USER_SELECTED_ORGANIZATION,
        payload: organization,
      });
      navigate(`/experiment${teamQuery}`);
    }
    dispatch({
      type: actionTypes.CLEAR_EXPERIMENTS_TABLE_FILTER,
      payload: {
        team: {
          filter: user.teams.join(','),
          filterType: 'text',
          type: 'inSet',
        },
      },
    });
    onOrganizationChanged(dispatch);
  };

  return (
    <Wrapper>
      {!isUserInfoFetching && email && (
        <>
          <SelectOrganization
            data={data}
            loading={loading}
            onOrganizationSelect={onOrganizationSelect}
            selectedOrganization={selectedOrganization}
          />

          <Menu
            id="auth-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleCloseAuthMenu}
          >
            <CustomMenuItem onClick={handleClickChangePassword}>
              Change Password
            </CustomMenuItem>
            <CustomMenuItem onClick={handleClickLogout}>Logout</CustomMenuItem>
          </Menu>

          <EmailWrapper onClick={handleClickAuthMenu}>
            <Typography
              style={{
                fontSize: 13,
                fontWeight: 500,
              }}
            >
              {email}
            </Typography>
            <AccountCircleIcon
              style={{
                width: 18,
                height: 18,
                marginLeft: 8,
              }}
            />
          </EmailWrapper>
        </>
      )}
    </Wrapper>
  );
};

const EmailWrapper = styled(Button)`
  display: flex;
  align-items: center;
  margin-left: auto;
`;

const Wrapper = styled.div`
  position: fixed;
  height: ${TOP_NAVBAR_HEIGHT}px;
  background: ${colors.grey200};
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 12px;
  z-index: 100;
`;

const CustomMenuItem = styled(MenuItem)`
  font-size: 13px;
`;

export default TopNavbar;
